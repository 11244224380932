<template>
  
  <section class="">
    <div class="container">
      <br />
      
      <p>Signed in as {{ user?.email }}</p>
      <button class="btn btn-danger" @click="signOutUser">Sign Out</button>

  

      <div>
        <button @click="viewAddNewCar()" class="btn btn-success float-end">
          Add New Car
        </button>
        <div class="clearfix"></div>
        <br />
        <div v-if="!is_DB_Connected">
          <div class="alert alert-danger fade show" v-if="emptyCars">
            {{ db_connection_msg }}
          </div>
        </div>
        <div v-else>{{ emptyCars }}</div>

        <!-- Show Data Here -->
        <div class="justify-content-evenly row">
          <div class="col-md-4 each-car" v-for="(eachCar, i) in cars" :key="i">
            <div class="card p-3 mb-2">
              <h4 class="text-center mt-1">
                {{ eachCar.name }}
              </h4>
              <img
                :src="getImageUrl(eachCar.image)"
                class="rounded d-block mx-auto img-fluid"
                :title="eachCar.name"
              />
              <h4 class="text-center">
                {{ eachCar.modelYear }}
              </h4>
              <div>
                <div class="float-start">
                  <div class="border rounded p-2">
                    {{ convertNumToPrice(eachCar.price) }}
                  </div>
                </div>
                <div class="float-end">
                  <button
                    class="btn btn-outline-primary mt-1 mb-1"
                    type="button"
                    data-bs-toggle="modal"
                    :data-bs-target="'#showCarDetails' + eachCar.id"
                  >
                    More Details
                  </button>
                  <ShowCarDetails :carData="eachCar" />
                </div>
              </div>
              <div class="clearfix"></div>
              <hr class="bg-success" />
              <div>
                <div class="float-start">
                  <button
                    class="btn btn-danger"
                    type="button"
                    data-bs-toggle="modal"
                    :data-bs-target="'#deleteCar-' + eachCar.id"
                  >
                    Delete
                  </button>
                  <DeleteCurrentCar
                    :carData="eachCar"
                    @updateCars="currentCars($events)"
                  />
                </div>
                <div class="float-end">
                  <button
                    type="button"
                    class="btn btn-success"
                    @click="goToUpdatePage(eachCar)"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import ShowCarDetails from "@/components/ShowCarDetails.vue";
import DeleteCurrentCar from "@/components/DeleteCurrentCar.vue";
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from "../firebase";
import { useRouter } from "vue-router";
export default {
  name: "homeView",
  components: {
    ShowCarDetails,
    DeleteCurrentCar,
  },
  setup() {
    const { user } = useAuthState();
    const auth = getAuth();

    const router = useRouter();
    const signOutUser = async () => {
      try {
        await signOut(auth);
        router.push("/login");
      } catch (e) {
        alert(e.message);
      }
    };

    return { user, signOutUser };
  },

  data() {
    return {
      is_DB_Connected: "",
      db_connection_msg: "",
      emptyCars: "",
      cars: [],
    };
  },

  mounted() {
    this.dbConnection();
  },
  methods: {
    convertNumToPrice(num) {
      return new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
      }).format(num);
    },
    getImageUrl(imgName) {
  return "https://carpool.ertugrulkok.com/images/" + imgName;
},

    viewAddNewCar() {
      this.$router.push({ name: "AddNewCar" });
    },
    goToUpdatePage(carData) {
      console.log(carData);
      localStorage.setItem("currentCar", JSON.stringify(carData));
      this.$router.push({ name: "UpdateCurrentCar" });
    },

    async myCars() {
      let res = await axios.get(
        "https://carpool.ertugrulkok.com/cars.php"
      );
      const resData = res.data;
      this.is_DB_Connected = resData.is_db_connected;
      this.db_connection_msg = resData.connection_msg;
    },
    async dbConnection() {
      let res = await axios.get(
        "https://carpool.ertugrulkok.com/cars.php?action=read"
      );
      const resData = res.data;
      if (res.status == 200) {
        if (resData.error) {
          this.emptyCars = resData.message;
        } else {
          this.cars = resData.cars;
        }
      }
      this.is_DB_Connected = resData.is_db_connected;
      this.db_connection_msg = resData.connection_msg;
    },
    currentCars(cars) {
      this.cars = cars;
      this.myCars();
    },
  },
};
</script>

<style scoped>
.each-car {
  margin-bottom: 10px;
}

.card {

  box-shadow: 0px 8px 29px 15px rgba(0, 0, 0, 0.1);
}



.each-car img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}

.d-flex{
  gap: 1rem;


}
 

</style>
