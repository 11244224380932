import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { ref, computed, onMounted, onUnmounted } from 'vue'

import { initializeApp } from 'firebase/app'

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyAQQ0K4jS_Ekx0rOCdtCeWOGEVkKWm99pk",
  authDomain: "cars-b9c07.firebaseapp.com",
  projectId: "cars-b9c07",
  storageBucket: "cars-b9c07.appspot.com",
  messagingSenderId: "145123172374",
  appId: "1:145123172374:web:8dfe8e62ad38de78225537",
  measurementId: "G-KM0J0BV1NG"
})

export const getUserState = () =>
  new Promise((resolve, reject) =>
    onAuthStateChanged(getAuth(), resolve, reject)
  )

export const useAuthState = () => {
  const user = ref(null)
  const error = ref(null)

  const auth = getAuth()
  let unsubscribe
  onMounted(() => {
    unsubscribe = onAuthStateChanged(
      auth,
      u => (user.value = u),
      e => (error.value = e)
    )
  })
  onUnmounted(() => unsubscribe())

  const isAuthenticated = computed(() => user.value != null)

  return { user, error, isAuthenticated }
}
