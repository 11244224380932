import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AddNewCar from '../components/AddNewCar.vue'
import UpdateCurrentCar from '../components/UpdateCurrentCar.vue'
import { getUserState } from '../firebase'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
    
  },
  {
    path: '/add/new-car',
    name: 'AddNewCar',
    component: AddNewCar,
  },
  {
    path: '/update/current-car',
    name: 'UpdateCurrentCar',
    component: UpdateCurrentCar,
  },
  {
    path: '/login',
    name: 'LogIn',
    component: () => import('../views/LogIn.vue'),
    meta: { requiresUnauth: true }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue'),
    meta: { requiresUnauth: true }
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth)

  const isAuth = await getUserState()

  if (requiresAuth && !isAuth) next('/login')
  else if (requiresUnauth && isAuth) next('/')
  else next()
})

export default router
