<template>
  <div
    class="modal fade"
    tabindex="-1"
    :id="'deleteCar-' + carData.id"
    :class="'deleteCar-' + carData.id"
    :aria-labelledby="carData.id"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ carData.name }}</h5>
          <button
            class="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body eachCarDetails">
  <img
    :src="getImageUrl(carData.image)"
    class="rounded d-block mx-auto img-fluid"
    :title="carData.name"
    :alt="carData.name"
  />
  <h4 class="text-center">
    {{ carData.name }} {{ carData.modelYear }}
  </h4>
  <hr class="bg-info" />

  <div class="d-flex justify-content-between">
    <button
      class="btn btn-outline-warning"
      type="button"
      data-bs-dismiss="modal"
      aria-label="close"
    >
      No,Cancel Deleting
    </button>
    <button
      class="btn btn-outline-danger"
      type="button"
      @click="deleteCar(carData)"
    >
      Yes,Delete it now
    </button>
  </div>
  <div class="row d-grid gap-2 col-auto d-block mx-auto my-3">
    <div class="alert alert-success" role="alert" v-if="resultSuccess">
      {{ resultSuccessMsg }}
    </div>
    <div class="alert alert-danger" role="alert" v-if="resultErr">
      {{ resultErrMsg }}
    </div>
  </div>
</div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DeleteCurrentCar",
  props: { carData: Object },

  data() {
    return {
      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: false,
    };
  },

  methods: {
    convertNumToPrice(num) {
      return new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
      }).format(num);
    },
    getImageUrl(imgName) {
  return "https://carpool.ertugrulkok.com/images/" + imgName;
},

    async deleteCar(obj) {
      let fd = new FormData();
      fd.append("name", obj.name);
      fd.append("id", obj.id);
      fd.append("image", obj.image);
      // for (let pair of fd.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      let res = await axios.post(
        "https://carpool.ertugrulkok.com/cars.php?action=deleteCar",
        fd
      );
      const resData = res.data;
      if (res.status == 200) {
        if (resData.error) {
          this.resultErr = true;
          this.resultErrMsg = resData.message;
          this.resultSuccess = false;
          this.resultSuccessMsg = resData.message;
        } else {
          this.resultErr = false;
          this.resultErrMsg = resData.message;
          this.resultSuccess = true;
          this.resultSuccessMsg = resData.message;
          let res2 = await axios.get(
            "https://carpool.ertugrulkok.com/cars.php?action=read"
          );
          setTimeout(() => {
            const resData2 = res2.data;
            this.$emit("updateCars", resData2.cars);
            this.hideCurrentModal("deleteCar-" + this.carData.id);
            }, 500);
        }
      }
    },
    hideCurrentModal(id) {
      document.getElementsByTagName("body")[0].removeAttribute("class");
      document.getElementsByTagName("body")[0].removeAttribute("style");

      //remove modal-backdrop element
      const elements = document.getElementsByClassName("modal-backdrop");
      while (elements.length > 0) elements[0].remove();

      // remove modal html
      const currentModal = document.getElementsByClassName(id);
      while (currentModal.length > 0) currentModal[0].remove();

      // Reload the page
  location.reload();
    },
  },
};
</script>

<style scoped>
.eachCarDetails {
  padding: 10px;
  margin-bottom: 10px;
  border: 10px solid #eee;
}
.eachCarDetails img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}
.bw {
  overflow-wrap: break-word;
}
</style>
