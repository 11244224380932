<template>
  <div>
    <div
      class="modal fade"
      tabindex="-1"
      :id="'showCarDetails' + carData.id"
      :aria-labelledby="carData.id"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ carData.name }}</h5>
            <button
              class="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body eachCarDetails">
            <img
              :src="getImageUrl(carData.image)"
              class="rounded d-block mx-auto img-fluid"
              :title="carData.name"
            />
            <h4 class="text-center">
              {{ carData.name }} {{ carData.modelYear }}
            </h4>
            <hr class="bg-info" />
            <p class="text-muted bw"> {{ carData.description }}</p>
            <div>
              <div class="float-start">
                <button class="btn btn-outline-info">
                  {{ convertNumToPrice(carData.price) }}
                </button>
              </div>
              <div class="float-end">
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Finished Reading
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowCarDetails",
  props: { carData: Object },

  methods: {
    convertNumToPrice(num) {
      return new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
      }).format(num);
    },
    getImageUrl(imgName) {
  return "https://carpool.ertugrulkok.com/images/" + imgName;
},
  },
};
</script>

<style scoped>
.eachCarDetails {
  padding: 10px;
  margin-bottom: 10px;
  border: 10px solid #eee;
}
.eachCarDetails img {
  width: 320px;
  height: 215px;
  margin-bottom: 10px;
}
.bw {
  overflow: break-word;
}
</style>
