<template>

<div class="container">
  <section class="mx-auto mt-3" style="max-width: 23rem;">
      
    <div class="card card-form">
      <div class="card-body rounded-top pink darken-4"
      style="box-shadow: 0px 8px 29px 15px rgba(0, 0, 0, 0.1);">
        <div>
        <div v-if="!is_DB_Connected">
          <div class="alert alert-danger fade show">
            {{ db_connection_msg }}
          </div>
        </div>
        <form v-else method="POST" action="#" enctype="multipart/form-data">
          <div class="row g-3 align-items-center mb-2">
            <div class="col-auto d-block mx-auto mt-4">
              <div
                class="form-floating"
                :class="{ 'form-data-error': carNameErr }"
              >
                <input
                  type="text"
                  id="carShopName"
                  placeholder="Car Name"
                  @input="validateCarName($event)"
                  @change="validateCarName($event)"
                  class="form-control w300"
                  v-model.trim="carName"
                />
                <label for="carShopName">Car Name</label>
                <span class="error-feedback" v-if="carNameErr">
                  {{ carNameMsg }}</span
                >
              </div>
            </div>
          </div>
          <div class="row g-3 align-items-center mb-2">
            <div class="col-auto d-block mx-auto mt-2">
              <div
                class="form-floating"
                :class="{ 'form-data-error': carPriceErr }"
              >
                <input
                  type="text"
                  id="carShopPrice"
                  placeholder="Car Price"
                  class="form-control w300"
                  @input="validateCarPrice($event)"
                  @change="validateCarPrice($event)"
                  v-model.trim="carPrice"
                />
                <label for="carShopPrice">Car Price</label>
                <span class="error-feedback" v-if="carPriceErr">
                  {{ carPriceMsg }}</span
                >
              </div>
            </div>
          </div>
          <div class="row g-3 align-items-center mb-2">
            <div class="col-auto d-block mx-auto mt-2">
              <div
                class="form-floating"
                :class="{ 'form-data-error': carModelYearErr }"
              >
                <input
                  type="number"
                  id="carShopModelYear"
                  placeholder="Car ModelYear"
                  class="form-control w300"
                  @input="validateCarModelYear($event)"
                  @change="validateCarModelYear($event)"
                  v-model.trim="carModelYear"
                />
                <label for="carShopModelYear">Car ModelYear</label>
                <span class="error-feedback" v-if="carModelYearErr">
                  {{ carModelYearMsg }}</span
                >
              </div>
            </div>
          </div>
          <div class="row g-3 align-items-center mb-2">
            <div class="col-auto d-block mx-auto mt-2">
              <div
                class="form-floating"
                :class="{ 'form-data-error': carImageErr }"
                v-if="!image"
              >
                <input
                  type="file"
                  id="carShopImage"
                  placeholder="Car Image"
                  class="form-control w300 customFileField"
                  @input="validateCarImage()"
                  @change="validateCarImage()"
                  ref="carImage"
                />
                <label for="carShopImage">Car Image</label>
                <span class="error-feedback" v-if="carImageErr">
                  {{ carImageMsg }}</span
                >
              </div>
              <div v-else class="text-center" style="background-color: #fff; width: 19rem;border-radius:8%" >
                 <span>Uploaded Car Image:</span><br/>
                <img :src="image" class="w150 rounded" /><br />
             
                <button
                  class="btn btn-outline-danger my-2"
                  @click="removeImage()"
                >
                  Remove Car Image
                </button>
              </div>
            </div>
          </div>
          <div class="row g-3 align-items-center mb-2">
            <div class="col-auto d-block mx-auto mt-2">
              <div
                class="form-floating"
                :class="{ 'form-data-error': carDescriptionErr }"
              >
                <textarea
                  id="carShopDescription"
                  placeholder="Car Description"
                  class="form-control w300"
                  v-model.trim="carDescription"
                  @input="validateCarDescription($event)"
                  @change="validateCarDescription($event)"
                ></textarea>
                <label for="carShopDescription">Car Description</label>
                <span class="error-feedback" v-if="carDescriptionErr">
                  {{ carDescriptionMsg }}</span
                >
              </div>
            </div>
          </div>

          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-2">
            <button
              class="btn btn-success"
              @click.prevent="addNewCar()"
            >
              Add New Car
            </button>
            <button
            @click="goHome()" class="btn btn-danger"
            >
              Cancel
            </button>
          </div>
          <div class="row d-grid gap-2 w300 col-auto d-block mx-auto mb-2">
            <div class="alert alert-success" role="alert" v-if="resultSuccess">
              {{ resultSuccessMsg }}
            </div>
            <div class="alert alert-danger" role="alert" v-if="resultErr">
              {{ resultErrMsg }}
            </div>
          </div>
        </form>
      </div>
       
      </div>
  
    </div>
    
  </section>
</div>
     
      
           
  

</template>

<script>
import axios from "axios";
export default {
  name: "AddNewCar",
  data() {
    return {
      carName: "",
      carNameErr: false,
      carNameMsg: "",
      isCarNameValidated: false,
      carPrice: "",
      carPriceErr: false,
      carPriceMsg: "",
      isCarPriceValidated: false,
      carModelYear: "",
      carModelYearErr: false,
      carModelYearMsg: "",
      isCarModelYearValidated: false,
      carImage: "",
      carImageErr: false,
      carImageMsg: "",
      isCarImageValidated: false,
      carDescription: "",
      carDescriptionErr: false,
      carDescriptionMsg: "",
      isCarDescriptionValidated: false,
      resultSuccess: false,
      resultSuccessMsg: "",
      resultErr: false,
      resultErrMsg: "",
      image: "",
      is_DB_Connected: "",
      db_connection_msg: "",
    };
  },
  mounted() {
    this.dbConnection();
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
    validateCarName(e) {
      let val = e.target.value;
      this.validateCarNameInput(val);
    },

    validateCarNameInput(val) {
      if (val == "") {
        this.carNameErr = true;
        this.isCarNameValidated = false;
        this.carNameMsg = "Enter a car name please!";
      } else if (val !== "" && !isNaN(val)) {
        this.carNameErr = true;
        this.isCarNameValidated = false;
        this.carNameMsg = "Enter only a text please!";
      } else if (val.length > 20) {
        this.carNameErr = true;
        this.isCarNameValidated = false;
        this.carNameMsg = "Car name is too long!";
      } else {
        this.carNameErr = false;
        this.isCarNameValidated = true;
        this.carNameMsg = "";
      }
    },
    validateCarPrice(e) {
      let val = e.target.value;
      this.validateCarPriceInput(val);
    },
    validateCarPriceInput(val) {
      if (val == "") {
        this.carPriceErr = true;
        this.isCarPriceValidated = false;
        this.carPriceMsg = "Enter a car price please!";
      } else if (val !== "" && isNaN(val)) {
        this.carPriceErr = true;
        this.isCarPriceValidated = false;
        this.carPriceMsg = "Enter only a number please!";
      } else if (val < 0) {
        this.carPriceErr = true;
        this.isCarPriceValidated = false;
        this.carPriceMsg = "Positive amount only!";
      } else if (val == 0 || val == 0.0) {
        this.carPriceErr = true;
        this.isCarPriceValidated = false;
        this.carPriceMsg = "Amount cannot be 0!";
      } else {
        this.carPriceErr = false;
        this.isCarPriceValidated = true;
        this.carPriceMsg = "";
      }
    },
    validateCarModelYear(e) {
      let val = e.target.value;
      this.validateCarModelYearInput(val);
    },
    validateCarModelYearInput(val) {
      if (val == "") {
        this.carModelYearErr = true;
        this.isCarModelYearValidated = false;
        this.carModelYearMsg = "Enter  a model year for car!";
      } else if (/^[0-9]+$/.test(val) === false) {
        this.carModelYearErr = true;
        this.isCarModelYearValidated = false;
        this.carModelYearMsg = "Enter only a number please!";
      } else if (val.length < 4 || val.length > 4) {
        this.carModelYearErr = true;
        this.isCarModelYearValidated = false;
        this.carModelYearMsg = "Enter a valid year format please!";
      } else {
        this.carModelYearErr = false;
        this.isCarModelYearValidated = true;
        this.carModelYearMsg = "";
      }
    },
    validateCarDescription(e) {
      let val = e.target.value;
      this.validateCarDescriptionInput(val);
    },
    validateCarDescriptionInput(val) {
      if (val == "") {
        this.carDescriptionErr = true;
        this.isCarDescriptionValidated = false;
        this.carDescriptionMsg = "Enter a description!";
      } else if (val.length > 100) {
        this.carDescriptionErr = true;
        this.isCarDescriptionValidated = false;
        this.carDescriptionMsg = "Maximum 100 char. please!";
      } else {
        this.carDescriptionErr = false;
        this.isCarDescriptionValidated = true;
        this.carDescriptionMsg = "";
      }
    },

    validateFileExtention(id) {
      let fileInput = document.getElementById(id);
      let filepath = fileInput.value;
      let allowedExtensions = /(\.jpg|\.png|\.jpeg|\.gif)$/i;
      if (!allowedExtensions.exec(filepath)) {
        return false;
      } else {
        return true;
      }
    },
    createImage(file) {
      new Image();
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.image = "";
      setTimeout(() => {
        this.validateCarImage();
      }, 500);
    },

    validateCarImage() {
      if (this.$refs.carImage.files[0]) {
        if (this.validateFileExtention("carShopImage") == true) {
          this.carImageErr = false;
          this.isCarImageValidated = true;
          this.carImageMsg = "";
          this.createImage(this.$refs.carImage.files[0]);
          this.carImage = this.$refs.carImage.files[0];
        } else {
          this.carImageErr = true;
          this.isCarImageValidated = false;
          this.carImageMsg = "Use a valid file extension";
        }
      } else {
        this.carImageErr = true;
        this.isCarImageValidated = false;
        this.carImageMsg = "Upload an image please!";
      }
    },
    async dbConnection() {
      let res = await axios.get(
        "https://carpool.ertugrulkok.com/cars.php"
      );
      const resData = res.data;
      this.is_DB_Connected = resData.is_db_connected;
      this.db_connection_msg = resData.connection_msg;
    },

    async addNewCar() {
      if (
        this.isCarNameValidated &&
        this.isCarPriceValidated &&
        this.isCarModelYearValidated &&
        this.isCarImageValidated &&
        this.isCarDescriptionValidated
      ) {
        console.log("validated scucessfully");
        let fd = new FormData();
        fd.append("name", this.carName);
        fd.append("price", this.carPrice);
        fd.append("description", this.carDescription);
        fd.append("yearModel", this.carModelYear);
        fd.append("image", this.carImage);

        let rst = await axios.post(
          `https://carpool.ertugrulkok.com/cars.php?action=update`,
          fd
        );
        // console.log(rst);
        const resultData = rst.data;
        if (rst.status == 200) {
          if (resultData.error) {
            this.resultErrMsg = resultData.message;
            this.resultErr = true;
            this.resultSuccessMsg = "";
            this.resultSuccess = false;
          } else {
            // if works perfectly
            this.resultErrMsg = "";
            this.resultErr = false;
            this.resultSuccessMsg = resultData.message;
            this.resultSuccess = true;
            setTimeout(() => {
              this.$router.push({ name: "home" });
            }, 2000);
          }
        }

      
      } else {
        console.log("failed validation form");
   

        this.validateCarNameInput(this.carName);
        this.validateCarPriceInput(this.carPrice);
        this.validateCarModelYearInput(this.carModelYear);
        this.validateCarDescriptionInput(this.carDescription);
        this.validateCarImage();
      }
    },
    resetFormError() {
      (this.carNameErr = false),
        (this.carNameMsg = ""),
        (this.carPrice = ""),
        (this.carPriceErr = false),
        (this.carPriceMsg = ""),
        (this.carModelYear = ""),
        (this.carModelYearErr = false),
        (this.carModelYearMsg = ""),
        (this.carImage = ""),
        (this.carImageErr = false),
        (this.carImageMsg = ""),
        (this.carDescription = ""),
        (this.carDescriptionErr = false),
        (this.carDescriptionMsg = ""),
        (this.resultSuccess = false),
        (this.resultSuccessMsg = ""),
        (this.resultErr = false),
        (this.resultErrMsg = "");
    },
  },
};
</script>

<style>
.w300 {
  width: 150px !important;
}
.w150 {
  width: 150px !important;
}
.h125 {
  height: 100px !important;
}
.form-data-error {
  color: red !important;
}
.form-data-error input,
.form-data-error textarea {
  border-color: red !important;
}
.error-feedback {
  padding-left: 5px;
  font-size: 0.9rem !important;
}
.customFileField {
  padding-left: 24px !important;
  padding-top: 38px !important;
  padding-bottom: 10px !important;
  height: 75px !important;
}
</style>
