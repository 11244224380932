<template>
  <div class="navbar navbar-dark bg-primary">
    <a href="https://ertugrulkok.com/">
      <button type="submit" class="btn btn-info btn-block btn-md gradient-custom-4 text-body">Back To Main</button>
    </a>
    <ul class="nav">
      <li v-if="isLoggedIn" class="nav-item">
        <router-link class="nav-link text-white" to="/">Home</router-link>
      </li>
      <li v-if="!isLoggedIn" class="nav-item">
        <router-link class="nav-link text-white" to="/login">Log In</router-link>
      </li>
      <li v-if="!isLoggedIn" class="nav-item">
        <router-link class="nav-link text-white" to="/signup">Sign Up</router-link>
      </li>
    </ul>
  </div>
  <router-view/>
</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth'

export default {
  data() {
    return {
      isLoggedIn: false
    }
  },
  created() {
    const auth = getAuth()

    onAuthStateChanged(auth, (user) => {
      this.isLoggedIn = !!user
    })
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

.navbar-brand {
    padding-left: 10px; 
}
</style>
